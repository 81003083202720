.hero {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 3rem 2.5rem 3rem 2.5rem;
  color: $color-white;
  background: url("../../assets/hero-bg.svg") center;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

  @include respond-to('screen-large') {
    flex-direction: row;
    gap: 5rem;
    padding: 6rem 5rem;
    background-position: center;
  }

  &__left {
    display: flex;
    flex-direction: column;

    @include respond-to('screen-large') {
      flex: 3;
      align-items: flex-start;
    }
  }

  &__right {
    display: flex;

    @include respond-to('screen-large') {
      flex: 2;
      align-items: center;
    }
  }

  &__title {
    font-size: 1.8rem;
    font-weight: 800;

    @include respond-to('screen-large') {
      font-size: 4rem;
      margin-top: 4rem;
      margin-bottom: 5rem;
    }

    span {
      display: block;
    }
  }

  &__text {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.71875rem;
    margin-bottom: 2rem;

    @include respond-to('screen-large') {
      font-size: 1.1625rem;
    }
  }

  &__button {
    flex: 1;
    font-size: 1.375rem;
    font-weight: 800;
    border-radius: 1rem;
    padding: 1.25rem 2rem;

    @include respond-to('screen-large') {
      font-size: 1.875rem;
      height: 4.5rem;
      padding: .75rem 2rem;
    }

    //&:nth-child(2) {
    //  background-color: white;
    //  color: $color-black;
    //
    //  &:hover {
    //    background-color: darken($color-white, 20%);
    //  }
    //}
  }

  &__cube {
    width: 100%;
    height: auto;

    @include respond-to('screen-large') {
      width: 30rem;
    }
  }
}
