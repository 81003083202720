/// Responsive breakpoint manager
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. '
         + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

@mixin page-padding {
  padding: 3rem 1rem 3rem 1rem;

  @include respond-to('screen-large') {
    padding: 6rem 5rem;
  }
}

@mixin divider-shadow {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  //box-shadow: $box-shadow-default;
}
