.m-bottom-4 {
  margin-bottom: 4rem;
}

.m-left-1 {
  margin-left: 1rem;
}

.m-left-2 {
  margin-left: 2rem;
}

.m-right-2 {
  margin-right: 2rem;
}

.p-05 {
  padding: .5rem;
}

.w-100 {
  width: 100%;
}

.gap-1 {
  gap: 1rem;
}
