@keyframes ShowIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(100%);
  }
}

@keyframes ShowOut {
  0% {
    opacity: 1;
    transform: scale(100%);
  }

  100% {
    opacity: 0;
    transform: scale(0);
  }
}
