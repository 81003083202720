.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1rem;
  font-weight: 700;
  line-height: 1.375rem;
  background-color: $color-orange;
  border-radius: $border-radius-default;
  transition: background-color $animation-duration;
  cursor: pointer;

  @include respond-to('screen-large') {
    padding: 0.75rem 2rem;
  }

  &:hover {
    background-color: darken($color-orange, 5%);
  }

  &:active {
    user-select: none;
  }

  &--disabled {
    background-color: darken($color-orange, 20%);
    cursor: not-allowed;

    &:hover {
      background-color: darken($color-orange, 20%);;
    }
  }

  &--secondary:hover {
    background-color: lighten($color-blue-dark, 15%);
  }

  &--secondary {
    border-radius: 1.5rem;
    background-color: $color-blue-dark;
    color: $color-white
  }
}
