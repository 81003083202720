.menu-button {
  cursor: pointer;
  width: 2rem;

  &--active &__line:nth-child(1) {
    transform: translate(0, 11px) rotate(-45deg);
  }

  &--active &__line:nth-child(2) {
    opacity: 0;
  }

  &--active &__line:nth-child(3) {
    transform: translate(0, -11px) rotate(45deg);
  }

  &__container {
    padding: 1rem;
  }

  &__line {
    height: 5px;
    border-radius: .5rem;
    background-color: $color-black;
    transition: $animation-duration;

    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }
}
