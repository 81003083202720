body {
  font-size: $base-font-size;
  font-family: $base-font-family;
  color: $text-color;
}

.font-lato {
  font-family: 'Lato', 'sans-serif';
}

.font-opensans {
  font-family: 'OpenSans', 'sans-serif';
}

@font-face {
  font-family: 'OpenSans';
  src: url('../../assets/OpenSans.ttf');
  font-weight: 1 999;
}
