.title {
  font-size: 1.8rem;
  font-weight: 800;
  margin-top: 0;

  @include respond-to('screen-large') {
    font-size: 4rem;
    margin-bottom: 1rem;
  }

  &--small {
    font-size: 1rem;

    @include respond-to('screen-large') {
      font-size: 2rem;
    }
  }

  span {
    display: block;
  }
}
