// COLORS
$color-purple: rgba(0, 0, 100, .5);
$color-purple-darkest: #4F4879;
$color-purple-dark: #6B5FBF;

$background-color: $color-purple;
$color-white: white;
$color-black: black;

$color-orange: #FF9400;
$color-purple: #3E3676;
$color-blue-dark: #2F246F;

// FONTS
$base-font-family: sans-serif;
$text-color: black;
$base-font-size: 16px;

// ANIMATION
$animation-duration: .3s;
$animation-duration-secondary: .6s;

// BORDER
$border-radius-default: 0.375rem;

// BOX-SHADOW
$box-shadow-default: 0 4px 15px 0 rgba(0, 0, 0, 0.25);

// Z-INDEX
$z-index-modal: 100;
$z-index-dropdown: 10;
$z-index-slider-arrows: 10;

